import React, { useState, useEffect } from "react";
import Img from "gatsby-image";
import { motion, AnimatePresence } from "framer-motion";

import Layout from "../../layout/Layout";
import StyledLink from "../../components/styled-link/StyledLink";
import OfferGuide from "../../components/offer-guide/OfferGuide";

import snubniprstenyStyle from "./assets/SnubniPrsteny.module.scss";

import snubniPrstynkyCollections from "../../queries/snubniPrstynkyCollections";

const SnubniPrstenyPage = () => {
  const snubniPrstenyCollectionsQuery = snubniPrstynkyCollections();
  const collectionsList =
    snubniPrstenyCollectionsQuery.django2.allCategories.edges[0].node
      .collectionSet.edges;

  useEffect(() => {
    collectionsList.forEach(collection => {
      new Image().src =
        collection.node.dressSet.edges[0].node.dresspictureSet.edges[0].node.originalPhotoSharp.childImageSharp.fluid;
    });
    // eslint-disable-next-line
  }, []);

  const [selectedCollection, setSelectedCollection] = useState(collectionsList[0]);

  const onCollectionHover = name => {
    setSelectedCollection(
      collectionsList.find(collection => collection.node.name === name)
    );
  };

  return (
    <Layout title="Snubní prsteny">
      <section name="collections" className={snubniprstenyStyle["collections"]}>
        <div className={snubniprstenyStyle["collections_container"]}>
          <div className={snubniprstenyStyle["collections_title"]}>
            <h1>Snubní prsteny</h1>
          </div>
          <div className={snubniprstenyStyle["collections_content"]}>
            <div className={snubniprstenyStyle["collections_description"]}>
              <p>Sleva 20% pro naše nevěsty na snubní prstnýky Retofy </p>
            </div>
            <div className={snubniprstenyStyle["collections_list"]}>
              {collectionsList &&
                collectionsList.map(collection => (
                  <div
                    key={collection.node.id}
                    className={snubniprstenyStyle["collections_list_item"]}
                  >
                    <StyledLink
                      target="_blank"
                      href={collection.node.url}
                      external={true}
                      onMouseEnter={() =>
                        onCollectionHover(collection.node.name)
                      }
                    >
                      {collection.node.name}
                    </StyledLink>
                  </div>
                ))}
            </div>
          </div>
          <div className={snubniprstenyStyle["collections_image"]}>
            <img
              className={snubniprstenyStyle["collections_image_placeholder"]}
              src={selectedCollection.image}
              alt="Svatební šaty"
            />
            <Img
              key={selectedCollection.node.dressSet.slug}
              fluid={
                selectedCollection.node.dressSet.edges[0].node.dresspictureSet
                  .edges[0].node.originalPhotoSharp.childImageSharp.fluid
              }
              alt={selectedCollection.node.dressSet.name}
            />
            <AnimatePresence>
              <motion.div
                className={snubniprstenyStyle["collections_image_motion"]}
                key={selectedCollection.name}
                initial={{ opacity: 0, transition: { duration: 0.5 } }}
                animate={{ opacity: 1, transition: { duration: 0.5 } }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.5, delay: 0.33 }
                }}
              >
                <Img
                  key={selectedCollection.node.dressSet.slug}
                  fluid={
                    selectedCollection.node.dressSet.edges[0].node
                      .dresspictureSet.edges[0].node.originalPhotoSharp
                      .childImageSharp.fluid
                  }
                  alt={selectedCollection.node.dressSet.name}
                />
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </section>

      <OfferGuide />
    </Layout>
  );
};

export default SnubniPrstenyPage;
