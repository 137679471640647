import { graphql, useStaticQuery } from "gatsby";

const snubniPrstynkyCollections = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const data = useStaticQuery(
    graphql`
      query {
        django2 {
          allCategories(slug: "prstynky") {
            edges {
              node {
                name
                slug
                collectionSet {
                  edges {
                    node {
                      id
                      name
                      url
                      note
                      dressSet {
                        edges {
                          node {
                            dresspictureSet {
                              edges {
                                node {
                                  originalPhotoSharp {
                                    childImageSharp {
                                      fluid {
                                        ...GatsbyImageSharpFluid
                                      }
                                    }
                                  }
                                  originalPhoto
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return data;
};

export default snubniPrstynkyCollections;
